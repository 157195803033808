import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {},
  loadingStudents: false,
  dataStudents: [],
};

export const dashboardReducer = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardRequest: (state) => {
      state.loading = true;
    },
    dashboardSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    dashboardError: (state) => {
      state.loading = false;
    },

    dashboardStudentRequest: (state) => {
      state.loadingStudents = true;
    },
    dashboardStudentSuccess: (state, action) => {
      state.loadingStudents = false;
      state.dataStudents = action.payload.data;
    },
    dashboardStudentError: (state) => {
      state.loadingStudents = false;
    },
  },
});

export const {
  dashboardRequest,
  dashboardSuccess,
  dashboardError,
  dashboardStudentRequest,
  dashboardStudentSuccess,
  dashboardStudentError,
} = dashboardReducer.actions;

export default dashboardReducer.reducer;
